import { useRef, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { firebaseApp } from "./firebase";

const auth = getAuth(firebaseApp);

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = "GET", data) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          signOut(auth);
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

/**
 * Calls a webhook with the provided URL and data.
 * @param {string} url - The URL of the webhook to call.
 * @param {Object} data - The data to send to the webhook.
 * @returns {Promise<Object>} The response from the webhook.
 * @throws {Error} If there's an error calling the webhook.
 */
export async function callWebhook(url, data = {}) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling webhook:', error);
    throw error;
  }
}

// Example usage:
// callWebhook('https://your-webhook-url.com/endpoint', { message: 'Hello, webhook!' })
//   .then(data => console.log('Webhook response:', data))
//   .catch(error => console.error('Webhook error:', error));