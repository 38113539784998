import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';

const allowedDomains = [
  'mindpal.space',
  'amazonaws.com',
  // Add more allowed domains here
];

const ResizableIframe = ({ url, initialHeight = 600, ...props }) => {
  const [iframeHeight, setIframeHeight] = useState(initialHeight);
  const [isUrlAllowed, setIsUrlAllowed] = useState(false);

  useEffect(() => {
    const checkUrl = () => {
      try {
        const urlObj = new URL(url);
        const isDomainAllowed = allowedDomains.some(domain => urlObj.hostname === domain || urlObj.hostname.endsWith(`.${domain}`));
        setIsUrlAllowed(isDomainAllowed);
      } catch (error) {
        console.error('Invalid URL:', error);
        setIsUrlAllowed(false);
      }
    };

    checkUrl();
  }, [url]);

  const increaseHeight = () => {
    setIframeHeight(prevHeight => prevHeight + 100);
  };

  const decreaseHeight = () => {
    setIframeHeight(prevHeight => Math.max(200, prevHeight - 100));
  };

  if (!isUrlAllowed) {
    return (
      <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
        <span className="mr-2">⚠️</span>
        <span>This URL is not allowed to be displayed in an iframe.</span>
      </div>
    );
  }

  return (
    <div className="w-full space-y-2">
      <div className="flex justify-end space-x-2">
        <button
          onClick={decreaseHeight}
          className="p-2 bg-gray-200 rounded hover:bg-gray-300"
          aria-label="Decrease height"
        >
          &#9650; {/* Up triangle */}
        </button>
        <button
          onClick={increaseHeight}
          className="p-2 bg-gray-200 rounded hover:bg-gray-300"
          aria-label="Increase height"
        >
          &#9660; {/* Down triangle */}
        </button>
      </div>
      <Iframe
        url={url}
        width="100%"
        height={`${iframeHeight}px`}
        className="border border-gray-300"
        {...props}
      />
    </div>
  );
};

export default ResizableIframe;