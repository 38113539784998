import React from "react";
import {
  AdjustmentsVerticalIcon,
  ChartPieIcon,
  GlobeAmericasIcon,
  BoltIcon,
} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import FeatureIcon2 from "./FeatureIcon2";

function FeaturesSection(props) {
  const features = {
    // Left column
    left: [
      {
        title: "Multi-AI Content Enhancement",
        description:
          "Leverage multi-layered AI processes to create, tailor, and refine content for your specific audience. Our AI tools not only generate initial content but continuously monitor performance and optimize for even greater impact.",
        icon: AdjustmentsVerticalIcon,
        iconColor: "orange",
      },
      {
        title: "Enhanced KPI Analysis and Optimization",
        description:
          "Empower your decision-making with our advanced AI-driven KPI analysis. Our platform continuously monitors your key performance indicators, delivering actionable insights in real time. Identify trends, uncover opportunities, and optimize your marketing strategies based on dynamic data analytics, all without manual intervention.",
        icon: ChartPieIcon,
        iconColor: "red",
      },
    ],
    // Right column
    right: [
      {
        title: "Smart Engagement Optimization",
        description:
          "Harness the power of our revolutionary multi-layered AI to identify the best times, subjects, and keywords for posting. Continuously improve engagement strategies daily, ensuring your content reaches its full potential and resonates with your audience.",
        icon: GlobeAmericasIcon,
        iconColor: "emerald",
      },
      {
        title: "Intelligent Automated Reporting",
        description:
          "Stay effortlessly informed with our intelligent automated reporting system. Our platform compiles and analyzes data across all channels to deliver comprehensive reports tailored to your needs. Gain deep insights with visualized data and customized metrics, ensuring you're always aligned with your business goals and strategies.",
        icon: BoltIcon,
        iconColor: "purple",
      },
    ],
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <div className="flex flex-col lg:flex-row lg:items-center space-y-12 lg:space-y-0 lg:space-x-20">
          <div className="lg:w-5/12 xl:w-5/12 md:py-12 space-y-10 ">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              strapline={props.strapline}
            />
          </div>
          <div className="lg:w-7/12 xl:w-7/12 flex-none relative">
            <div className="pattern-dots opacity-10 absolute top-0 left-0 w-48 h-64 md:mt-20 transform -translate-x-10 -translate-y-10" />
            <div className="pattern-dots opacity-10 absolute bottom-0 right-0 w-48 h-64 md:mb-20 transform translate-x-10 translate-y-10" />
            <div className="relative flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
              <div className="md:w-1/2 md:mt-24 space-y-6 bg-transparent">
                {features.left.map((feature, index) => (
                    <div
                      className="group bg-white p-5 transition ease-out duration-200 border-2 border-gray-200 rounded-2xl hover:border-gray-300"
                      key={index}
                    > 
                      <FeatureIcon2 color={feature.iconColor} className="mb-6">
                        <feature.icon />
                      </FeatureIcon2>
                      <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
                      <p className="leading-relaxed text-gray-600">
                        {feature.description}
                      </p>
                    </div>
                ))}
              </div>
              <div className="md:w-1/2 space-y-6">
                {features.right.map((feature, index) => (
                    <div
                      className="group bg-white p-5 transition ease-out duration-200 border-2 border-gray-200 rounded-2xl hover:border-gray-300"
                      key={index}
                    >
                      <FeatureIcon2 color={feature.iconColor} className="mb-6">
                        <feature.icon />
                      </FeatureIcon2>
                      <h4 className="text-lg font-bold mb-2">{feature.title}</h4>
                      <p className="leading-relaxed text-gray-600">
                        {feature.description}
                      </p>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

    </Section>
  );
}

export default FeaturesSection;
