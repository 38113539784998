import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
// import { Link } from "./../util/router";
// import { useAuth } from "./../util/auth";
import ResizableIframe from "./ResizableIframe";


function DashboardSetup(props) {
  // const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={"Welcome to Sunokrom! Begin your journey with our AI-driven Autopilot by taking advantage of our free website assessment. Start optimizing your online presence today."}
          strapline={"AI-driven Marketing Autopilot"}
          className="text-center"
        />

        <div className="max-w-full mx-auto p-6 bg-white rounded-lg shadow-md">
          <p className="text-gray-700 mb-4">Begin your journey with our AI-driven Autopilot:</p>

          <ol className="list-decimal list-inside space-y-2 mb-6">
            <li className="text-gray-800">Enter your website URL (including https://), e.g., <code
              className="bg-gray-100 rounded px-2 py-1 text-sm">https://www.sunokrom.com</code></li>
            <li className="text-gray-800">Upload a screenshot of your website's landing page</li>
            <li className="text-gray-800">Select your preferred language for the report</li>
          </ol>

          <p className="text-lg font-semibold text-green-600">Start your free website assessment and optimize your
            online presence today!</p>
        </div>

        <div className="flex flex-wrap min-h-[900px]">
          <ResizableIframe
            url="https://workflow.mindpal.space/66be923bb7007132105cd8eb"
            id="my-iframe"
            initialHeight={900}  // You can set a custom initial height
          />

        </div>
      </div>
    </Section>
  );
}

export default DashboardSetup;
