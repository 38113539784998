import React from "react";
import Meta from "./../components/Meta";
import DashboardSetup from "./../components/DashboardSetup";
import { requireAuth } from "../util/auth";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSetup
        title="Website assessment"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(DashboardPage);
